<template>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
        <el-form-item label="消息类型">
            <el-select v-model="dataForm.type" placeholder="请选择要发布的消息类型">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
<!--            <el-input v-model="dataForm.type" placeholder="请选择要发布的消息类型"></el-input>-->
        </el-form-item>
        <el-form-item label="消息内容" prop="content">
            <el-input v-model="dataForm.content" placeholder="请撰写消息内容" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="dataFormSubmit()">提交</el-button>
            <el-button @click="empty()">清空</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    export default {
        name: "message-release",
        data(){
            return {
                dataForm:{
                    id: 0,
                    type: '1',
                    content: '',
                },
                dataRule: {
                    content: [
                        { required: true, message: '内容不能为空', trigger: 'blur' }
                    ],
                },
                options: [{
                    value: '1',
                    label: '新闻类'
                }, {
                    value: '2',
                    label: '通知类'
                }, {
                    value: '3',
                    label: '系统类'
                }],
            }
        },
        methods:{
            // 表单提交
            dataFormSubmit () {
                this.$refs['dataForm'].validate((valid) => {
                    if (valid) {
                        this.$message.success("提交成功")
                        this.dataForm={
                            id: 0,
                            type: '1',
                            content: '',}
                    }})
            },
            empty(){
                this.dataForm={
                    id: 0,
                    type: '1',
                    content: '',}
            }
        }
    }
</script>

<style scoped>

</style>
